<template>
    <div class="edu-frame">
        <edu-nav-bar title="资料修改" :show="show" :hideBack="$route.query.hideBack==1" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">填写报名资料</div>
                <div class="step-tips-detail" style="color: black;">请填写您的基本资料（以下带<span style="color: red;">*</span>的是<span style="color: red;">必填项</span>）</div>
                <div class="photo-frame">
                    <img v-if="formData.imgUrl == null || formData.imgUrl == ''" class="photo" src="../../statics/images/my.png">
                    <img v-else class="photo" :src="formData.imgUrl">
                    <div class="photo-tips">*要求相片为白底证件照</div>
                    <!--图片为空 或者 overCheckIDQty == 0都显示上传按钮-->
                    <template v-if="overCheckIDQty == 0 || (formData.imgUrl == null || formData.imgUrl == '' || formData.imgUrl.indexOf('base64') > 0)">
                        <template  v-if="formData.fage <= 15 || (formData.imgUrl == null || formData.imgUrl == ''  || formData.imgUrl.indexOf('base64') > 0)">
                            <van-button type="default" style="margin-top: 10px" @click="chooseImage">选择照片</van-button>
                            <input style="display: none;" id="browse" ref="browse" type="file" @change="previewFiles($event)">
                        </template>

                        <div v-if="formData.fage <= 15" class="photo-tips">
                            每个人只允许修改三次姓名、身份证号码、及相片。请确保上述三项资料输入正确后，再提交修改。
                        </div>
                    </template>
                    <div v-if="overCheckIDQty == 1">
                        您已经修改了3次个人资料，不允许再修改姓名、身份证号码、及相片。<br/>如果需要修改这三项信息，请与中心工作人员联系。
                    </div>
                    <!--图片为空 或者 overCheckIDQty == 0都显示上传按钮-->

                </div>
                <div class="class-form">
                    <van-field readonly v-model="formData.idCardNo" required label="身份证号码" placeholder="请输入身份证号码" clearable/>
                    <van-field required name="radio" label="性别">
                        <template #input>
                            <van-radio-group v-model="formData.sex" direction="horizontal">
                                <van-radio name="1">男</van-radio>
                                <van-radio name="2">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field readonly v-model="formData.fullName" required label="姓名" placeholder="请输入姓名" clearable/>
                    <van-field required readonly clickable name="datetimePicker" :value="formData.birthday_GreStr" label="出生日期" placeholder="点击选择时间" @click="birthdayPicker = true"/>
                    <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable/>
                    <van-field required v-model="formData.urgencyMan" label="紧急联系人" placeholder="请输入紧急联系人" clearable/>
                    <van-field required v-model="formData.urgencyTelNo" label="联系人电话" placeholder="请输入联系人电话" clearable/>
                    <van-field label-width="80px" required name="radio" label="发票类型">
                      <template #input>
                        <van-radio-group v-model="formData.lsrtype" direction="horizontal" @change="formData.depTaxNo = ''">
                          <van-radio name="1">个人</van-radio>
                          <van-radio name="2">单位</van-radio>
                        </van-radio-group>
                      </template>
                    </van-field>
                    <van-field label-width="90px" v-model="formData.taxDepName" required label="开票单位名称" placeholder="请输入开票单位名称" clearable/>
                    <van-field label-width="80px" v-if="formData.lsrtype == 2" v-model="formData.depTaxNo" required label="单位纳税人识别号" placeholder="请输入单位纳税人识别号" clearable/>

                    <van-field label-width="90px" v-model="formData.unitAddressAndPhone" label="单位地址及电话" placeholder="请输入单位地址及电话" clearable/>
                    <van-field label-width="90px" v-model="formData.bankAccountInfo" label="开户银行信息" placeholder="请输入开户银行信息" clearable/>

                    <van-field v-if="formData.fage >=18" required v-model="formData.healthStatus" label="疾病史" placeholder="请输入疾病史" clearable/>
                    <van-field v-if="formData.fage >=18" required v-model="formData.unitName" :label="formData.fage < 18 ? '就读学校': '退休前单位'" :placeholder="formData.fage < 18 ?'请输入就读学校' :'请输入退休前单位'" clearable/>
                    <van-field v-if="formData.fage >=18" required v-model="formData.oriDuty" label="退休前职务" placeholder="请输入退休前职务" clearable/>
                    <van-field v-model="formData.nativePlace" label="籍贯" placeholder="请输入籍贯" clearable/>
                    <van-field v-model="formData.nationality" label="民族" placeholder="请输入民族" clearable/>
                    <van-field readonly clickable v-model="formData.politicsStatusStr" label="政治面貌" placeholder="请选择政治面貌" clearable @click="showPolitics = true"/>
                    <van-field v-model="formData.eduLevel" label="文化程度" placeholder="请输入文化程度" clearable/>
                    <van-field readonly clickable v-model="formData.isOnDutyStr" label="在职状况" placeholder="请选择在职状况" clearable @click="showIsOnDuty = true"/>
                </div>
            </div>
        </div>

        <!-- 保存按钮 -->
        <div class="bottom-frame">
            <van-button :loading="submitting" square block type="primary" @click="submit">保存</van-button>
        </div>
        <!-- 保存按钮 -->

        <!-- 选择生日 -->
        <van-popup v-model="birthdayPicker" position="bottom">
            <van-datetime-picker v-model="formData.birthday_Gre" value type="date" title="选择年月日"  :min-date="minDate" :max-date="maxDate" @confirm="selectBirthday()" @cancel="birthdayPicker=false"/>
        </van-popup>
        <!-- 选择生日 -->
        <!--选择政治面貌-->
        <van-popup v-model="showPolitics" position="bottom">
            <van-picker show-toolbar :columns="politicsOpts" @confirm="selectPolitics" @cancel="showPolitics=false;"/>
        </van-popup>
        <!--选择政治面貌-->
        <!--选择在职状况-->
        <van-popup v-model="showIsOnDuty" position="bottom">
            <van-picker show-toolbar :columns="isOnDutyOpts" @confirm="selectIsOnDuty" @cancel="showIsOnDuty=false;"/>
        </van-popup>
        <!--选择在职状况-->
    </div>
</template>
<script>
import {Button, Field, RadioGroup, Radio, Popup, DatetimePicker, Picker} from "vant";
import EduNavBar from "@/components/EduNavBar";
import Tools from "@/api/Tools";
import XEUtils from 'xe-utils'
import CustomerApi from "@/api/CustomerApi";

export default {
    components: {
        EduNavBar,
        VanButton: Button,
        VanField: Field,
        VanRadioGroup: RadioGroup,
        VanRadio: Radio,
        VanDatetimePicker: DatetimePicker,
        VanPopup: Popup,
        VanPicker: Picker
    },
    data() {
        return {
            show: false,
            birthdayPicker: false, // 出生日期
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
            showPolitics: false, // 政治面貌
            overCheckIDQty: 0, // 判断是否已经超过3次修改照片
            formData: { sex: '1', birthdayStr: new Date() },
            showIsOnDuty: false, // 在职状况
            politicsOpts: [{ text: '团员', value: '1' }, { text: '党员', value: '2' }, { text: '其他', value: '3' }],
            isOnDutyOpts: [{ text: '离职', value: '0' }, { text: '在职', value: '1' }],
            submitting: false,
            loginType: Tools.getLoginType()
        }
    },
    methods: {
        selectBirthday() {
            // 选择出生日期
            // this.formData.birthday_Gre = e
            this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
            // this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
            this.birthdayPicker = false
        },
        selectPolitics(e) {
            // 选择政治面貌
            this.formData.politicsStatusStr = e.text
            this.formData.politicsStatus = e.value
            this.showPolitics = false
        },
        selectIsOnDuty(e) {
            // 选择在职状况
            this.formData.isOnDutyStr = e.text
            this.formData.isOnDuty = e.value
            this.showIsOnDuty = false
        },
        previewFiles(){
            var file = document.getElementById("browse").files[0];
            var self = this
            Tools.uploadFileByBase64(file, (e)=>{
                self.$set(self.formData, 'imgUrl', e)
                console.log(self.formData.imgUrl)
            })
        },
        chooseImage() {
            this.$refs.browse.click()
        },
        submit() {
            if (this.submitting) {
              return
            }
            // 提交修改的资料
            this.submitting = true
            this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
            CustomerApi.completeMsg(this.formData).then(response => {
                this.submitting = false
                // console.log(response)
                if (response.code==100) {
                    this.$toast({message: response.msg});
                    Tools.setCurCust(response.res)
                    // 如果是从登陆中补完资料进入 需要再判断是否有空字段，如果有，则保留在此页，否则跳到主页
                    if (this.$route.query.hideBack == 1) {
                        CustomerApi.checkHasBlankField(response.res.custId).then(resp=>{
                            if (resp.res == 0) {
                                this.$router.replace({ name: 'main' })
                            }
                        })
                    }
                }
            }).catch(() => { this.submitting = false })
        }
    },
    created() {
        // 当前登录账户
        this.formData = Tools.getCurCust()
        if (this.formData.depTaxNo != null && this.formData.depTaxNo != '') {
          this.formData.lsrtype = '2'
        } else {
          this.formData.lsrtype = '1'
        }


        console.log(this.formData.birthday_Gre)
        this.formData.birthday_Gre = XEUtils.toStringDate(XEUtils.toDateString(this.formData.birthday_Gre), 'yyyy-MM-dd HH:mm:ss')
        this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
        // 判断是否超过3次认证
        CustomerApi.overCheckIDQty(this.formData.custId).then(response => {
            this.overCheckIDQty = response.res
        })
    }
}
</script>
<style scoped>
    .photo-frame {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 15px auto;
    }
    .photo {
        width: 35%;
    }
    .photo-tips {
        color: red;
        font-size: 14px;
        margin-top: 5px;
    }
</style>
